<template>
  <v-card>
    <v-card-title> Usuários por construção </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>
              {{ userContruction.id ? "Editar" : "Novo" }} vinculo ao Usuário
              <v-spacer></v-spacer>
              <v-btn @click="showForm = !showForm" color="warning"
                >{{ showForm ? "Esconder" : "Mostrar" }} Formulario</v-btn
              >
            </v-card-title>
            <v-card-text v-show="showForm">
              <v-row>
                <v-col>
                  <v-autocomplete
                    outlined
                    v-model="userContruction.userId"
                    :items="getUserSelect"
                    label="Selelcione o Usuário"
                    hint="De qual Obra esse usuário é"
                    persistent-hint
                  ></v-autocomplete>
                </v-col>
                <v-col>
                  <v-autocomplete
                    outlined
                    v-model="userContruction.constructionId"
                    :items="getConstructionsSelect"
                    label="Selelcione a Obra"
                    hint="Qual obra usuario visualiza"
                    persistent-hint
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-spacer></v-spacer>
                <v-btn color="warning" @click="clearForm">Cancelar</v-btn>
                <v-btn class="mx-2" color="success" @click="save">Salvar</v-btn>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- filtrar por usuario ou obra -->
      <v-row>
        <v-col>
          <v-autocomplete
            outlined
            :items="getUserSelect"
            label="Filtrar por usuário"
            persistent-hint
            multiple
            chips
            small-chips
            deletable-chips
            v-model="filter.usersId"
            @change="setFilter"
          ></v-autocomplete>
        </v-col>
        <v-col>
          <v-autocomplete
            outlined
            :items="getConstructionsSelect"
            label="Filtrar por Obra"
            persistent-hint
            multiple
            chips
            small-chips
            deletable-chips
            v-model="filter.constructionsId"
            @change="setFilter"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-simple-table>
            <thead>
              <tr>
                <th class="text-left">ID</th>
                <th class="text-left">Usuário</th>
                <th class="text-left">Obra</th>
                <th class="text-left">Criado</th>
                <th class="text-left">Alterado</th>
                <th class="text-left">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in getObraUsuarioFilter" :key="index">
                <td>{{ item.id }}</td>
                <td>{{ getUserNameById(item.userId) }}</td>
                <td>{{ getConstructionNameById(item.constructionId) }}</td>
                <td>{{ new Date(item.createdAt).toLocaleString() }}</td>
                <td>{{ new Date(item.updatedAt).toLocaleString() }}</td>
                <td>
                  <v-icon small @click="del(item)" class="m-1">
                    mdi-trash-can
                  </v-icon>
                  <v-icon small @click="edt(item)"> mdi-lead-pencil </v-icon>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "motoristas",
  computed: {
    ...mapGetters([
      "getUsers",
      "getConstructionsSelect",
      "getUserSelect",
      "getObraUsuario",
      "getUserNameById",
      "getConstructionNameById",
      "getObraUsuarioFilter",
    ]),
  },
  methods: {
    save() {
      this.$store.dispatch("saveObraUsuario", this.userContruction);
    },
    clearForm() {
      this.user = {};
    },
    del(e) {
      this.$store.dispatch("deleteObraUsuario", e);
    },
    edt(e) {
      console.log(e);
      this.userContruction.constructionId = parseFloat(e.constructionId);
      this.userContruction.userId = parseFloat(e.userId);
      this.showForm = true;
    },
    setFilter() {
      this.$store.dispatch("setUsuarioContrucaoFilter", this.filter);
    },
  },
  data: () => ({
    showForm: false,
    userContruction: {},
    filter: {
      usersId: [],
      constructionsId: [],
    },
  }),
  created() {
    this.$store.dispatch("loadUsers");
    this.$store.dispatch("loadUsuarioContrucao");
    this.$store.dispatch("loadConstructions");
  },
};
</script>

<style>
</style>